import ecommerce from '../../../../../ecommerce/ecwid/custom';
import dom from '../../../../../wrapper/DomWrapper';
import WidgetWrapper from '../../../../../wrapper/WidgetWrapper';

class StorefrontPage extends WidgetWrapper {
  // eslint-disable-next-line class-methods-use-this
  init = async () => {
    const elStorefront = dom.getElement('.storefront-wrapper');
    const section = elStorefront?.closest('.s-section');

    if (section) {
      section.removeAttribute('style');
      dom.removeElement(dom.getElement('.section_bg', section));
    }

    dom.addClass(elStorefront, 'spinner');
    dom.updateStyle(elStorefront, { minHeight: `${Math.round(dom.window.innerHeight / 2)}px` });
    await ecommerce.provider.initStorefront(elStorefront, 'storefront-page');
    dom.removeClass(elStorefront, 'spinner');
  };
}

export default StorefrontPage;
